<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" lg="3" md="3" sm="3">
        <search @search="search"/>
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="2">
        <CreateBusinessModal v-if="isAdmin" @refresh="refresh"/>
      </v-col>
    </v-row>
    <v-spacer></v-spacer>
    <v-row>
      <v-progress-linear
          :active="$apollo.queries.businesses.loading"
          :indeterminate="$apollo.queries.businesses.loading"
          absolute
          color="#ff3e00"
          top
      ></v-progress-linear>
      <BusinessGrid :businesses="businesses" :loading="$apollo.queries.businesses.loading"/>
      <Adsense
          data-ad-client="ca-pub-6855584270685805"
          data-ad-slot="2285122371">
      </Adsense>
    </v-row>
  </v-container>
</template>
<script>
import {BusinessCard} from "@/components";
import {GET_ALL_BUSINESSES, GET_BUSINESSES,} from "@/modules/businesses/graph/queries";
import CreateBusinessModal from "@/components/modals/createBusinessModal";
import {mapGetters} from "vuex";
import Search from "@/components/Search";
import BusinessGrid from "@/modules/businesses/BusinessesGrid";

export default {
  name: "Businesses",
  components: {
    BusinessGrid,
    Search,
    CreateBusinessModal,
    BusinessCard,
  },
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: 'Businesses',
  },
  data: function () {
    return {
      count: 10,
      businesses: [],
      searchTerm: "%%",
      gridList: true,
    };
  },
  computed: {
    ...mapGetters(["isAdmin"]),
  },
  methods: {
    toggleChange(event) {
      this.gridList = event;
    },
    refresh() {
      this.$apollo.queries.businesses.refetch();
    },
    search(e) {
      if (e === null) {
        this.searchTerm = "%" + '' + "%";
      } else {
        this.searchTerm = "%" + e + "%";
      }
    },
  },
  apollo: {
    businesses: {
      fetchPolicy: "cache-and-network",
      query() {
        return this.isAdmin ? GET_ALL_BUSINESSES : GET_BUSINESSES;
      },
      variables() {
        return {
          name: this.searchTerm,
        };
      },
    },
  },
};
</script>
