<template>
  <v-container fluid>
    <v-row >
      <v-col
          v-for="business in businesses"
          :key="business.id"
          xl="2"
          lg="3"
          md="4"
          sm="6"
          cols="12"
      >
        <v-skeleton-loader
            v-if="loading"
            class="mx-auto"
            max-width="1280"
            width="100%"
            type="card"
        ></v-skeleton-loader>
        <business-card v-else :key="business.id" :business="business"/>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import {mapGetters} from "vuex";
import BusinessCard from "@/components/cards/BusinessCard";

export default {
  name: "BusinessGrid",
  components: {BusinessCard},
  props: {
    loading:{
      type: Boolean,
      default: false,
      required:true
    },
    businesses: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  computed: {
    ...mapGetters(["isAdmin"]),
  },
  methods: {
    showCreateModal() {
      this.$modal.show("createBusinessModal");
    },
  },
};
</script>
